<ng-container *ngIf="displayType === 'badge'">
  <ng-container *ngIf="dataCounterService.dataCountR.value$ | async as count; else falsyCountTemplate">
    <mat-icon *ngIf="count?.value || ( showZeroCount && count?.value===0); else falsyCountTemplate" matListItemIcon
      [matBadge]="count.value">{{ badgeIcon }}</mat-icon>
  </ng-container>
</ng-container>

<ng-container *ngIf="displayType === 'text'">
  <ng-container *ngIf="dataCounterService.dataCountR.value$ | async as count">
    <span *ngIf="count?.value">
      {{textPrefix??''}}{{count.value}}{{textSuffix??''}}
    </span>
  </ng-container>
</ng-container>

<ng-template #falsyCountTemplate>
  <mat-icon matListItemIcon>{{ badgeIcon }}</mat-icon>
</ng-template>