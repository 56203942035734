import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { Favorite } from '@rcg/filters';
import { IntlModule } from '@rcg/intl';
import { FavoritesMenuPosition, NavMenuItem } from './models/nav-menu-item.model';
import { NavMenuComponent } from './nav-menu/nav-menu.component';

@Component({
  selector: 'rcg-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatExpansionModule,
    RouterModule,
    NavMenuComponent,
    MatTooltipModule,
    IntlModule,
  ],
})
export class SideNavComponent implements OnInit {
  @Input() navMenuItems: NavMenuItem[] = [];

  @Input() favorites?: Favorite[] = [];

  @Input() favoritesMenuPosition?: FavoritesMenuPosition = 'top';

  @Input() initialCategory?: NavMenuItem | null | undefined;

  @Input() isExpanded = false;

  @Input() categoryTitle = '';

  @Input() routable = true;

  @Input() menuAlwaysExpanded = false;

  @Input() menuItemsExpanded = true;

  @Input() showZeroCount = true;

  @Input() expandedIcon = 'more_horiz';

  @Output() navMenuItemClicked = new EventEmitter<NavMenuItem>();

  @Output() selectedFavorite = new EventEmitter<Favorite>();

  ngOnInit(): void {
    if (this.menuAlwaysExpanded) {
      this.isExpanded = true;
    }
  }

  onNavMenuItemClicked(navItem: NavMenuItem): void {
    this.navMenuItemClicked.emit(navItem);
  }
}
