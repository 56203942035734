<div style="display: flex; flex-direction: column; height: 100%">
  @if(gridTitleTr){
  <mat-toolbar>{{ gridTitleTr | intl }}</mat-toolbar>
  } @if(loading()){
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  } @else if(error()){
  <div class="center-text" style="color: red">Error: {{ error() }}</div>
  }@else{ @if(data(); as data){
  <rcg-templater-view--data-grid [config]="data.config" [dataConnectorConfigs]="data.data_connector_configs" [runtimeData$]="runtimeData$">
  </rcg-templater-view--data-grid>
  } }
</div>
