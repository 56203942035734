@if(favorites&& favorites.length>0 && favoritesMenuPosition==='top'){
<rcg-favorites-menu-item
  [favorites]="favorites | categoryTranslation : navMenuItems"
  [expanded]="isExpanded"
  (selectedFavorite)="selectedFavorite.emit($event)"
></rcg-favorites-menu-item>
}

<ng-container *ngIf="navMenuItems && navMenuItems.length > 0">
  <ng-container *ngFor="let item of navMenuItems">
    <ng-container
      [ngTemplateOutlet]="item.children && item.children.length ? children : noChildren"
      [ngTemplateOutletContext]="{ item: item }"
    >
    </ng-container>
  </ng-container>

  <ng-template #children let-item="item">
    <mat-expansion-panel [expanded]="menuItemsExpanded">
      <mat-expansion-panel-header style="min-width: 56px">
        <mat-panel-title [matTooltip]="item?.translation?.text ? (item.translation.text | intl) : item?.text ? item.text : '[?]'">
          {{ item?.translation?.text ? (item.translation.text | intl) : item?.text ? item.text : '[?]' }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <!-- Recursion -->
      <rcg-nav-menu
        [showZeroCount]="showZeroCount"
        [navMenuItems]="item.children"
        [level]="level + 1"
        [isExpanded]="isExpanded"
        [routable]="routable"
        (navMenuItemClicked)="!routable ? onNavMenuItemClicked($event) : false"
      ></rcg-nav-menu>
    </mat-expansion-panel>
  </ng-template>

  <ng-template #noChildren let-item="item">
    <div [ngStyle]="{ 'padding-left': level > 1 && isExpanded ? '12px' : '' }">
      <a
        mat-list-item
        [routerLinkActive]="routable ? 'rcg-active-route' : ''"
        [routerLink]="routable ? item?.routerLink : null"
        [queryParams]="routable ? item?.queryParams : null"
        [rcgActiveRoute]="!routable ? item?.routerLink : null"
        [rcgQueryParams]="!routable ? item?.queryParams : null"
        [matTooltip]="item?.translation?.text ? (item.translation.text | intl) : item?.text ? item.text : '[?]'"
        [matTooltipPosition]="'left'"
        (click)="!routable ? onNavMenuItemClicked(item) : false"
      >
        <rcg-data-counter
          *ngIf="item?.countData && item?.dataCounter?.gql; else noCounterTemplate"
          [displayType]="item.dataCounter.type"
          [gqlInput]="item.dataCounter.gql"
          [badgeIcon]="item.icon"
          [showZeroCount]="showZeroCount"
          matListItemIcon
        ></rcg-data-counter>

        <ng-template #noCounterTemplate>
          <mat-icon matListItemIcon *ngIf="!item?.countData || !item?.dataCounter">{{ item?.icon }} </mat-icon>
        </ng-template>

        <span matListItemTitle>{{ item?.translation?.text ? (item.translation.text | intl) : item?.text ? item.text : '[?]' }}</span>
      </a>
    </div>
  </ng-template>
</ng-container>

@if(favorites&& favorites.length>0 && favoritesMenuPosition==='bottom'){
<rcg-favorites-menu-item
  [favorites]="favorites | categoryTranslation : navMenuItems"
  [expanded]="isExpanded"
  (selectedFavorite)="selectedFavorite.emit($event)"
></rcg-favorites-menu-item>
}
