import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { TabSetting } from './models/tabs.model';

@Component({
  selector: 'rcg-tabs',
  standalone: true,
  imports: [CommonModule, MatTabsModule, MatIconModule, NgTemplateOutlet],
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent {
  @Input() tabs: TabSetting[] = [];

  selectedTabIndex = 0;
}
