<ng-container *ngIf="data">
  <div class="container">
    <!-- row 1 -->
    <div class="item-row">
      <span>
        <ng-container *ngIf="data?.customer?.short_name">
          <span style="font-size: 14px; font-weight: 500; margin-right: 4px">
            {{ data.customer.short_name ?? '' | slice : 0 : 25 }}{{ data.customer.short_name.length > 20 ? '....' : '' }}
          </span>
        </ng-container>
      </span>
      <span style="font-size: 12px">
        {{ data?.created_at | date : 'short' }}
      </span>
    </div>
    <!-- row 2 -->
    <div class="item-row">
      <div style="font-size: 12px">
        {{ data?.short_description ?? '' | slice : 0 : 120 }}{{ (data.short_description ?? '').length > 120 ? ' ....' : '' }}
      </div>
      <div style="font-size: 12px">
        {{ data?.updated_at | dateToNow }}
      </div>
    </div>
    <!-- row 3 -->
    <div class="item-row">
      <div style="font-size: 12px">
        {{ data?.project_category?.description ?? '' }}
      </div>
      <div style="font-size: 12px">
        {{ data?.code ?? '' }}
      </div>
    </div>
  </div>
</ng-container>
