/* eslint-disable @typescript-eslint/no-explicit-any */

import { TemplateRef } from '@angular/core';

export interface TabSetting {
  id: number;
  label?: string;
  icon?: string;
  disabled?: boolean;
  hidden?: boolean;
  component?: any;
  template?: TemplateRef<any> | undefined | null;
  templateName?: string;
  data?: any;
}
