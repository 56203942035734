import { Pipe, PipeTransform } from '@angular/core';
import { Favorite } from '@rcg/filters';
import { NavMenuItem } from '../models/nav-menu-item.model';

@Pipe({
  name: 'categoryTranslation',
  pure: true,
  standalone: true,
})
export class CategoryTranslationPipe implements PipeTransform {
  transform(favorites: Favorite[], navmenu: NavMenuItem[]): Favorite[] {
    if (favorites && favorites.length > 0 && navmenu && navmenu.length > 0) {
      return favorites.map((fav) => {
        const nav = navmenu.find((cat) => cat.category === fav.category);
        if (nav?.translation?.text) {
          return {
            ...fav,
            category_translation: nav.translation.text,
          };
        }
        return fav;
      });
    }
    return favorites;
  }
}
