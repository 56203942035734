import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RcgPipesModule, TenantId } from '@rcg/core';

@Component({
  selector: 'rcg-project',
  standalone: true,
  imports: [CommonModule, RcgPipesModule],
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() data: any;

  @Input() tenantId!: number;

  @Input() category: string | undefined;

  tenant = TenantId;
}
