<ng-container>
  <mat-card appearance="outlined" class="data-sum">
    <mat-icon>{{ icon ?? 'functions' }}</mat-icon>
    <div *ngIf="dataCounterService.dataCountR.value$ | async as count; else noCount">
      <div style="display: flex; justify-content: end; gap: 8px">
        <span>{{ count?.value ?? 0 | secondsToTime }}</span>
        <ng-container *ngIf="actions && actions.length > 0">
          <rcg-actions
            [actions]="actions"
            [actionSize]="'22px'"
            [actionViewType]="'icon'"
            (actionClicked)="action.emit($event)"
          ></rcg-actions>
        </ng-container>
        <ng-container *ngIf="filtersSettings?.id">
          <rcg-filters [count]="dataCount" [filterSetting]="filtersSettings" (filter)="onFilterChange($event)"> </rcg-filters>
        </ng-container>
      </div>
    </div>
  </mat-card>
</ng-container>

<ng-template #noCount> 0:00 </ng-template>
