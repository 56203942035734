<ng-container *ngIf="tabs && tabs.length > 0">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" [selectedIndex]="selectedTabIndex">
    <ng-container *ngFor="let tab of tabs">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon ngClass="tab-icon" *ngIf="tab.icon" class="tab-icon">{{ tab.icon }}</mat-icon>
          {{ tab.label ?? '' }}
        </ng-template>

        <ng-container *ngIf="tab?.template as template">
          <!-- dynamic tab template -->
          <ng-container [ngTemplateOutlet]="template!" [ngTemplateOutletContext]="{ data: tab?.data }"></ng-container>
        </ng-container>
        <!-- dynamic tab component -->
        <ng-container *ngIf="tab?.component as component">
          {{ component }}
        </ng-container>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</ng-container>
