import { gql } from 'apollo-angular';

export const gridQuery = gql`
  query ($id: Int!) {
    data: view_configs_grid_by_pk(id: $id) {
      config
      data_connector_configs
      path
      icon_item
    }
  }
`;
