import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DataConnectorConfig } from '@npm-libs/ng-templater/lib/engine/models/data/data-connector.model';
import { GraphqlClientService } from '@rcg/graphql';
import { IntlModule } from '@rcg/intl';
import { BehaviorSubject, map } from 'rxjs';
import { DataGridViewComponent, DataGridViewConfig } from '../../templater/data-grid/data-grid.view.component';
import { gridQuery } from './gql/grid.gql';

export interface GridData {
  config: DataGridViewConfig;
  data_connector_configs: DataConnectorConfig[];
}

@Component({
  selector: 'rcg-grid',
  standalone: true,
  imports: [CommonModule, DataGridViewComponent, MatProgressBarModule, MatToolbarModule, IntlModule],
  templateUrl: './grid.component.html',
  styleUrl: './grid.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RcgGridComponent implements OnInit {
  @Input() gridId!: number;

  @Input() gridTitleTr: string | undefined;

  @Input() runtimeData: { [x: string]: unknown } | undefined;

  runtimeData$: BehaviorSubject<{ [x: string]: unknown }> = new BehaviorSubject<{ [x: string]: unknown }>({});

  gqlClient = inject(GraphqlClientService);
  destroyRef = inject(DestroyRef);

  error = signal<string | null>(null);
  loading = signal<boolean>(false);
  data = signal<GridData | undefined>(undefined);

  ngOnInit(): void {
    if (!this.gridId) {
      this.error.set('Grid ID is required.');
      return;
    }

    if (this.runtimeData) {
      this.runtimeData$.next(this.runtimeData);
    }

    this.getGridData(this.gridId);
  }

  getGridData(id: number) {
    this.loading.set(true);
    this.error.set(null);

    return this.gqlClient
      .query<{ data?: GridData | undefined }>({
        query: gridQuery,
        variables: {
          id: id,
        },
      })
      .pipe(
        map((res) => {
          if (!res?.data || res.data?.config === undefined || res.data?.data_connector_configs === undefined) {
            throw new Error('No grid settings found or settings are incomplete.');
          }
          return res.data;
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (data) => {
          this.data.set(data);
          this.loading.set(false);
        },
        error: (err) => {
          this.error.set(err.message);
          this.loading.set(false);
        },
      });
  }
}
