<ng-container *ngIf="navMenuItems && navMenuItems.length > 0">
  <mat-sidenav-container class="container" autosize>
    <mat-sidenav mode="side" disableClose="true" opened="true">
      <div class="menu-icon">
        <mat-icon [matTooltip]="(isExpanded ? 'close_menu' : 'expand_menu') | intl" (click)="isExpanded = !isExpanded" aria-label="Menu">{{
          expandedIcon
        }}</mat-icon>
      </div>
      <mat-nav-list [style.width]="menuAlwaysExpanded === true ? 'auto' : isExpanded ? 'auto' : '56px'">
        <rcg-nav-menu
          [showZeroCount]="showZeroCount"
          [navMenuItems]="navMenuItems"
          [routable]="routable"
          [menuItemsExpanded]="menuItemsExpanded"
          [favorites]="favorites"
          [isExpanded]="isExpanded"
          [favoritesMenuPosition]="favoritesMenuPosition"
          (selectedFavorite)="selectedFavorite.emit($event)"
          (navMenuItemClicked)="onNavMenuItemClicked($event)"
        ></rcg-nav-menu>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
