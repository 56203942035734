import { gql } from 'apollo-angular';

export const ticketMutation = gql`
  mutation updateHelpdeskMutation($id: bigint!, $status_id: Int!) {
    data: update_servicedesk_helpdesk_by_pk(pk_columns: { id: $id }, _set: { status_id: $status_id }) {
      id
      status_id
    }
  }
`;
