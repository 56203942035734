import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IntlModule } from '@rcg/intl';
import { ElementReferenced, fromElement } from '../element-referenced.decorator';
import { ModuleLayoutComponent } from '../module-layout.component';

const selector = 'rcg-module-layout-detail-close-button';

@ElementReferenced
@Component({
  selector,
  templateUrl: './detail-close-button.component.html',
  styleUrls: ['./detail-close-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatTooltipModule, IntlModule],
})
export class ModuleLayoutDetailCloseButtonComponent implements AfterViewInit {
  @HostBinding('class.visible') visible = false;

  @Output() closeClick = new EventEmitter<void>();

  constructor(public el: ElementRef, private changeRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const layout = ModuleLayoutComponent.closest(this.el.nativeElement);
      this.visible = layout?.visible ?? false;
      this.changeRef.markForCheck();
    });
  }

  static setButtonsVisible(parentEl: HTMLElement, visible: boolean) {
    for (const el of parentEl.querySelectorAll(selector)) {
      const component = fromElement(ModuleLayoutDetailCloseButtonComponent, el);

      if (!component) continue;
      component.setVisible(visible);
    }
  }

  setVisible = (visible: boolean) => {
    this.visible = visible;

    try {
      this.changeRef.markForCheck();
    } catch (error) {
      //ignore
    }
  };
}
