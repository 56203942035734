/* eslint-disable @typescript-eslint/no-explicit-any */

import { AfterViewInit, Component, ElementRef } from '@angular/core';

type ComponentType = Component & Partial<AfterViewInit> & { el: ElementRef };

export interface FromRef<T extends new (...arg: any[]) => any> {
  fromRef: (el: Element) => InstanceType<T>;
}

const componentRefPropName = '__rcg_componentRef';

export function ElementReferenced<T extends new (...arg: any[]) => any>(target: T & Partial<FromRef<T>>) {
  const targetProto = target.prototype as ComponentType;
  const targetNgAfterViewInit = targetProto.ngAfterViewInit;

  target.fromRef = function (el: Element) {
    return (el as any)[componentRefPropName] as InstanceType<T>;
  };

  targetProto.ngAfterViewInit = function (this: ComponentType) {
    const el = this.el.nativeElement;
    el[componentRefPropName] = this;

    targetNgAfterViewInit?.call(this);
  };

  return target;
}

export function fromElement<T extends new (...arg: any[]) => any>(c: T, el: Element) {
  const ComponentClass = c as T & FromRef<T>;
  return ComponentClass.fromRef(el);
}
