<rcg-side-nav
  class="sidenav"
  [navMenuItems]="navMenuItems"
  [routable]="routable"
  [menuAlwaysExpanded]="menuAlwaysExpanded"
  [menuItemsExpanded]="menuItemsExpanded"
  [favorites]="favorites"
  [showZeroCount]="showZeroCount"
  [favoritesMenuPosition]="favoritesMenuPosition"
  (navMenuItemClicked)="onNavMenuItemClicked($event)"
  (selectedFavorite)="selectedFavorite.emit($event)"
>
  <nav>
    <ng-content select="[rcgModuleNav]"></ng-content>
  </nav>

  <mat-divider class="divider" [vertical]="true"></mat-divider>

  <main>
    <ng-content select="[rcgModuleDetail]"></ng-content>
  </main>
</rcg-side-nav>
