import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModuleLayoutDetailCloseButtonComponent } from '../module-layout/detail-close-button/detail-close-button.component';

@Component({
  selector: 'rcg-detail-header',
  templateUrl: './detail-header.component.html',
  styleUrls: ['./detail-header.component.scss'],
  standalone: true,
  imports: [CommonModule, MatCardModule, MatTooltipModule, ModuleLayoutDetailCloseButtonComponent],
})
export class DetailHeaderComponent {
  @Output() closeDetail = new EventEmitter<void>();
}
