<div style="display: flex; justify-content: center">
  <rcg-module-search
    style="padding-left: 4px; max-width: 25%"
    [search_CAPTION]="'Search'"
    (searchPhrase)="search($event)"
    [debounceTime]="0"
  >
  </rcg-module-search>
  <rcg-filters style="margin-top: 12px" [filterSetting]="filterSettings()"> </rcg-filters>
</div>

<div cdkDropListGroup *ngIf="data() | mapData : config.props?.columns as data" class="kanban-container">
  @if (config.props?.columns; as columns) { @for (column of columns; track $index) {
  <div class="list-container" [ngClass]="column.isExpanded !== false ? '' : 'hidden'">
    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
      <h2 style="padding: 8px 12px; margin: 0">
        {{ column.headerText | dataReference : dataReferenceContext }} - {{ data[column['keyField']].length }}
      </h2>
      <button mat-icon-button (click)="setExpanded(column, false)">
        <mat-icon style="transform: rotate(90deg)">expand_more</mat-icon>
      </button>
    </div>

    <div
      class="drop-zone-container"
      [ngStyle]="
        dragging && column.isExpanded !== false
          ? { visibility: 'visible', height: '500px' }
          : { visibility: 'hidden', height: '0px', 'margin-bottom': '0px' }
      "
    >
      <div style="display: flex; flex-direction: column; height: 500px; width: 100%">
        @for (item of column.keyField| extractKeys; track $index) {
        <div
          cdkDropList
          [id]="item"
          class="drop-zone-item"
          style="display: flex; flex-direction: column"
          (cdkDropListDropped)="drop($event)"
        >
          {{ item }}
        </div>
        }
      </div>
    </div>

    <div
      [id]="column['keyField']"
      cdkDropList
      class="list"
      *ngIf="data[column['keyField']] as listData"
      [cdkDropListEnterPredicate]="dropDownListEnter"
    >
      @for (item of listData; track $index) {
      <div
        [id]="item.id"
        class="drag-item"
        cdkDrag
        (cdkDragStarted)="setDragging(true)"
        (cdkDragReleased)="setDragging(false)"
        [cdkDragData]="item"
      >
        @if(item.moving) {
        <div class="progress-spinner-item">
          <mat-progress-spinner mode="indeterminate" diameter="30"></mat-progress-spinner>
        </div>
        }
        <rcg-helpdesk-item
          [style.height]="'100px'"
          [data]="item"
          category="kanban"
          [tenantId]="item.tenant.id"
          [id]="item.id"
          openOnDoubleClick="dialog"
        ></rcg-helpdesk-item>
      </div>
      }
    </div>
  </div>

  <div class="column-collapsed" [ngClass]="column.isExpanded !== false ? 'hidden' : ''">
    <button mat-icon-button (click)="setExpanded(column, true)">
      <mat-icon>expand_more</mat-icon>
    </button>
    <h2 class="rotated-header">{{ column.headerText | dataReference : dataReferenceContext }} - {{ data[column['keyField']].length }}</h2>
  </div>
  } }
</div>
