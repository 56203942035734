<mat-card class="container" [appearance]="'outlined'">
  <rcg-module-layout-detail-close-button style="grid-area: close" (closeClick)="closeDetail.emit()"></rcg-module-layout-detail-close-button>

  <div style="grid-area: status">
    <ng-content select="[detailHeaderStatus]"></ng-content>
  </div>

  <div style="grid-area: info">
    <ng-content select="[detailHeaderInfo]"></ng-content>
  </div>

  <div class="footer" style="grid-area: footer">
    <div><ng-content select="[detailHeaderExtra]"></ng-content></div>
    <div class="actions"><ng-content select="[detailHeaderActions]"></ng-content></div>
  </div>
</mat-card>
